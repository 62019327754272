import React, { useState } from "react";
import { Link } from "react-router-dom";
import defaultProductImage from "../images/main/products1.jpeg"; // Assuming a default image for products

function SearchByBudget() {
    const [activeTab, setActiveTab] = useState("all");
    const [wishlist, setWishlist] = useState({});

    // Static product data for different categories
    const products = {
        all: [
            { id: 1, name: "Maruti Suzuki Alto 800", category: "0-50 Lakh", price: 200000, image: null },
            { id: 2, name: "Toyota Fortuner", category: "5-10 Lakh", price: 700000, image: null },
            { id: 3, name: "Maruti Suzuki Wagon R", category: "10-15 Lakh", price: 150000, image: null },
        ],
        sorting1: [
            { id: 1, name: "Maruti Suzuki Alto 800", category: "0-50 Lakh", price: 200000, image: null },
            { id: 3, name: "Maruti Suzuki Wagon R", category: "0-50 Lakh", price: 1200000, image: null },
        ],
        sorting2: [
            { id: 2, name: "Toyota Fortuner", category: "5-10 Lakh", price: 700000, image: null },
        ],
        sorting3: [
            { id: 3, name: "Maruti Suzuki Wagon R", category: "10-15 Lakh", price: 150000, image: null },
        ],
        sorting4: [
            { id: 4, name: "Toyota Fortuner", category: "15-20 Lakh", price: 300000, image: null },
        ],
    };

    // Handle tab click
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const formatPrice = (price) => {
        if (price >= 10000000) {
            return `Rs. ${(price / 10000000).toFixed(2)} Crore`;
        } else if (price >= 100000) {
            return `Rs. ${(price / 100000).toFixed(2)} Lakh`;
        }
        return `Rs. ${price.toLocaleString()}`;
    };
    const toggleWishlist = (carId) => {
        setWishlist((prevState) => ({
            ...prevState,
            [carId]: !prevState[carId], // Toggle the state for the clicked car
        }));
    };
    return (
        <section className="searchByBudget default-cars-advertise">
            <div className="container">
                {/* Section Header */}
                <div className="row">
                    <div className="head">
                        <h2 className="xlSize fw6">Search by Budget</h2>
                        <a href="/explore-car" className="btn p-0">
                            VIEW ALL <ion-icon name="chevron-forward-outline"></ion-icon>
                        </a>
                    </div>
                </div>

                {/* Tabs for Product Categories */}
                <div className="sortingAccordingType sortingTabs">
                    <div className="typeTabs tabsFields">
                        <button
                            className={`tabsLink ${activeTab === "all" ? "active" : ""}`}
                            onClick={() => handleTabClick("all")}
                        >
                            All
                        </button>
                        <button
                            className={`tabsLink ${activeTab === "sorting1" ? "active" : ""}`}
                            onClick={() => handleTabClick("sorting1")}
                        >
                            0-5 Lakh
                        </button>
                        <button
                            className={`tabsLink ${activeTab === "sorting2" ? "active" : ""}`}
                            onClick={() => handleTabClick("sorting2")}
                        >
                            5-10 Lakh
                        </button>
                        <button
                            className={`tabsLink ${activeTab === "sorting3" ? "active" : ""}`}
                            onClick={() => handleTabClick("sorting3")}
                        >
                            10-15 Lakh
                        </button>
                        <button
                            className={`tabsLink ${activeTab === "sorting4" ? "active" : ""}`}
                            onClick={() => handleTabClick("sorting4")}
                        >
                            15-20 Lakh
                        </button>
                    </div>
                </div>

                {/* Products List */}
                <div className="row">
                    {products[activeTab].map((product) => (
                        <div className="col-md-4" key={product.id} >
                            <div className="card">
                                <div className="card-body p-0">
                                    <figure className="m-0">
                                        <img
                                            src={product.image || defaultProductImage}
                                            alt=""
                                            className="arrow-img W100"
                                        />
                                        <button
                                            className={`wishlistBtn ${wishlist[product.id] ? 'active' : ''}`}
                                            onClick={() => toggleWishlist(product.id)}
                                        >
                                            <ion-icon name="bookmark-outline"></ion-icon>
                                        </button>
                                    </figure>
                                    <Link to={`/car-details/${product.id}`} className="link-color">
                                        <div className="card-content">
                                            <div className="carOverview">
                                                <div className="dGridMobile d-flex justify-content-between">
                                                    <h5 className="card-title mSize my-auto">{product.name}</h5>
                                                    <p className="productCategory carType">{product.category}</p>
                                                </div>

                                                <div className="carHighlights d-flex gap-2">
                                                    <p className="sSize fw6">20,000 kms</p> <span className="separator">|</span>
                                                    <p className="sSize fw6">Automatic</p> <span className="separator">|</span>
                                                    <p className="sSize fw6">Rajasthan</p>
                                                </div>
                                            </div>
                                            <div className="carPriceSec d-flex justify-content-between">

                                                <h6 className="carName theme lSize fw5 productPrice">{formatPrice(product.price)}</h6>
                                                <a href="sellCarDetails" className="borderBtn ">
                                                    View Details
                                                </a>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section >
    );
}

export default SearchByBudget;
