import React, { useEffect, useState } from "react";
import applyForLoan from "../images/applyForLoan.png";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="faqDiv">
      <section class="DefaultTopBanner p-5"><div class="container">
        <div class="row">
          <div className="row white"><h1>Frequently Asked Questions (FAQs)</h1></div>
        </div>
      </div>
      </section>
      <div className="container ">
        <div className="row">
          <div className="col-md-5 mobile-view ">
            <div className="card">
              <div className="applyForLoanDiv">
                <div>
                  <div className="content">
                    <h2>Check your eligibility & apply online</h2>

                    <p className="s-para m-0">
                      {" "}
                      Get instant approval. Become a Car owner today!
                    </p>
                  </div>
                  <a target="_blank" href="https://web.whatsapp.com/" className="button">
                    <div className="">Apply For a Loan</div>
                  </a>
                </div>
                <figure className="m-0">
                  <img src={applyForLoan} alt="" className="W100" />
                </figure>
              </div>
            </div>
          </div>
          <div className="col-md-7 ">
            <div className=" ">
              <div className="accordionItems">
                <div className="card border-none">
                  <h5>For Dealers:</h5>
                  <div className="accordion">
                    <div className="heading" onClick={() => toggleAccordion(0)}>
                      How can I register as a dealer on Used Car Wale?
                    </div>
                    <div className={`contents ${activeIndex === 0 ? 'active' : ''}`}>
                      To register as a dealer, simply click on the "Dealer Registration" link on our homepage and complete the required information. Once your account is approved, you'll have access to a range of tools to manage and list your inventory.
                    </div>

                    <div className="heading" onClick={() => toggleAccordion(1)}>
                      What are the benefits of listing as a dealer on Used Car Wale?
                    </div>
                    <div className={`contents ${activeIndex === 1 ? 'active' : ''}`}>
                      As a dealer, you'll receive enhanced visibility on our platform, the ability to list multiple vehicles, access to premium advertising options, and advanced analytics to track your listings' performance.
                    </div>

                    <div className="heading" onClick={() => toggleAccordion(2)}>
                      Are there any fees for dealers to list cars on Used Car Wale?
                    </div>
                    <div className={`contents ${activeIndex === 2 ? 'active' : ''}`}>
                      We offer both free and paid listing options for dealers. Free listings provide basic visibility, while paid listings offer premium placements and extended durations. For detailed pricing, contact our call centre.
                    </div>

                    <div className="heading" onClick={() => toggleAccordion(3)}>
                      How can I manage my listings?
                    </div>
                    <div className={`contents ${activeIndex === 3 ? 'active' : ''}`}>
                      Once registered, dealers can log into their account dashboard where they can easily add, edit, renew, or remove vehicle listings.
                    </div>
                    <div className="heading" onClick={() => toggleAccordion(4)}>
                      Can I bulk upload my inventory?
                    </div>
                    <div className={`contents ${activeIndex === 4 ? 'active' : ''}`}>
                      Yes, dealers can bulk upload their inventory (without vehicle photos) using our CSV upload feature, making it easy to list multiple vehicles at once.
                    </div>
                    <div className="heading" onClick={() => toggleAccordion(5)}>
                      What kind of support do dealers receive?
                    </div>
                    <div className={`contents ${activeIndex === 5 ? 'active' : ''}`}>
                      We provide dedicated customer support for dealers (from 10:00 am to 8:00 pm), including assistance with listing setup, advertising options, and any technical issues that may arise.
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordionItems">
                <div className="card border-none">
                  <h5>For   Individuals:</h5>
                  <div className="accordion">
                    <div className="heading" onClick={() => toggleAccordion(6)}>
                      How do I list my used car for sale on Used Car Wale?
                    </div>
                    <div className={`contents ${activeIndex === 6 ? 'active' : ''}`}>
                      Listing your car is easy! Just click on "Sell Your Car" on our homepage, fill in the necessary details about your vehicle, upload photos, and submit your listing. Your ad will be live after a quick review.
                    </div>

                    <div className="heading" onClick={() => toggleAccordion(7)}>
                      Is there a fee to list my car on Used Car Wale?
                    </div>
                    <div className={`contents ${activeIndex === 7 ? 'active' : ''}`}>
                      We offer both free and paid listing options for individual sellers. Free listings have a limited duration, while paid listings can enhance visibility and stay live for longer periods.</div>

                    <div className="heading" onClick={() => toggleAccordion(8)}>
                      Are there any fees for dealers to list cars on Used Car Wale?
                    </div>
                    <div className={`contents ${activeIndex === 8 ? 'active' : ''}`}>
                      We offer both free and paid listing options for dealers. Free listings provide basic visibility, while paid listings offer premium placements and extended durations. For detailed pricing, contact our call centre.
                    </div>

                    <div className="heading" onClick={() => toggleAccordion(9)}>
                      What information do I need to provide to list my car?
                    </div>
                    <div className={`contents ${activeIndex === 9 ? 'active' : ''}`}>
                      You will need to provide the make, model, year, mileage, condition, and other relevant details of your vehicle, along with high-quality photos. </div>
                    <div className="heading" onClick={() => toggleAccordion(10)}>
                      How long will my listing stay active?
                    </div>
                    <div className={`contents ${activeIndex === 10 ? 'active' : ''}`}>
                      Free listings are active for 7 days. You can renew your listing before it expires or upgrade to a paid listing for extended visibility.
                    </div>
                    <div className="heading" onClick={() => toggleAccordion(11)}>
                      Can I edit or update my listing after it's posted?
                    </div>
                    <div className={`contents ${activeIndex === 11 ? 'active' : ''}`}>
                      Yes, you can edit your listing at any time by logging into your account. You can update the price, description, and photos as needed.
                    </div>
                    <div className="heading" onClick={() => toggleAccordion(12)}>
                      How do I respond to inquiries from potential buyers?
                    </div>
                    <div className={`contents ${activeIndex === 12 ? 'active' : ''}`}>
                      When someone is interested in your car, you will receive an email notification with their contact details. You can then communicate directly with the buyer to arrange a viewing or discuss the sale.
                    </div>
                    <div className="heading" onClick={() => toggleAccordion(13)}>
                      What should I do once my car is sold?
                    </div>
                    <div className={`contents ${activeIndex === 13 ? 'active' : ''}`}>
                      Once your car is sold, please log into your account and mark the listing as sold or remove it from the platform to avoid further inquiries.
                    </div>
                    <div className="heading" onClick={() => toggleAccordion(14)}>
                      Is my personal information safe on Used Car Wale?
                    </div>
                    <div className={`contents ${activeIndex === 14 ? 'active' : ''}`}>
                      Yes, we take your privacy seriously. Your personal information is protected and only shared with potential buyers as needed to facilitate the sale.
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordionItems">
                <div className="card border-none">
                  <h5>Benefits of Listing Used Cars on Used Car Wale:</h5>
                  <div className="accordion">
                    <div className="heading" onClick={() => toggleAccordion(15)}>
                      Wide Reach & Visibility:
                    </div>
                    <div className={`contents ${activeIndex === 15 ? 'active' : ''}`}>
                      Listing your car on Used Car Wale exposes it to a large audience of potential buyers across India, increasing your chances of a quick sale.
                    </div>

                    <div className="heading" onClick={() => toggleAccordion(16)}>
                      User-Friendly Platform:
                    </div>
                    <div className={`contents ${activeIndex === 16 ? 'active' : ''}`}>
                      Our platform is designed to make the listing process simple and efficient, allowing you to upload your vehicle details and photos in just a few easy steps..</div>

                    <div className="heading" onClick={() => toggleAccordion(17)}>
                      Free & Paid Listing Options:
                    </div>
                    <div className={`contents ${activeIndex === 17 ? 'active' : ''}`}>
                      Whether you're looking for a basic free listing or a premium paid option with enhanced visibility, we offer flexible choices to suit your needs.
                    </div>

                    <div className="heading" onClick={() => toggleAccordion(18)}>
                      Trust & Credibility:
                    </div>
                    <div className={`contents ${activeIndex === 18 ? 'active' : ''}`}>
                      Listing your car on a reputable platform like Used Car Wale adds credibility, making buyers more confident in the legitimacy of your vehicle.
                    </div>
                    <div className="heading" onClick={() => toggleAccordion(19)}>
                      Detailed Vehicle Listings:
                    </div>
                    <div className={`contents ${activeIndex === 19 ? 'active' : ''}`}>
                      Our platform allows you to provide comprehensive details about your car, including high-quality images, which helps attract serious buyers and reduces the need for lengthy negotiations.
                    </div>
                    <div className="heading" onClick={() => toggleAccordion(20)}>
                      24/7 Access:
                    </div>
                    <div className={`contents ${activeIndex === 20 ? 'active' : ''}`}>
                      Your listing is accessible to potential buyers at any time, allowing for continuous exposure and inquiries, even outside of regular business hours.
                    </div>
                    <div className="heading" onClick={() => toggleAccordion(21)}>
                      Dedicated Customer Support:
                    </div>
                    <div className={`contents ${activeIndex === 21 ? 'active' : ''}`}>
                      Our support team is always available (from 10:00 am to 8:00 pm) to assist you with any questions or issues you may encounter during the listing process.
                    </div>
                    <div className="heading" onClick={() => toggleAccordion(22)}>
                      Enhanced Communication Tools:
                    </div>
                    <div className={`contents ${activeIndex === 22 ? 'active' : ''}`}>
                      Directly communicate with interested buyers through our platform, streamlining the negotiation process and ensuring clear, effective communication.
                    </div>

                  </div>
                </div>
              </div>

              <div className="accordionItems">
                <div className="card border-none">
                  <h5>Why Used Car Wale Inspection is Necessary:</h5>
                  <div className="accordion">
                    <div className="heading" onClick={() => toggleAccordion(23)}>
                      Accurate Valuation:
                    </div>
                    <div className={`contents ${activeIndex === 23 ? 'active' : ''}`}>
                      Our thorough inspection process ensures that your vehicle is accurately valued based on its current condition, helping you set a fair and competitive price.
                    </div>

                    <div className="heading" onClick={() => toggleAccordion(24)}>
                      Builds Buyer Confidence:
                    </div>
                    <div className={`contents ${activeIndex === 24 ? 'active' : ''}`}>
                      An inspection report from Used Car Wale provides buyers with detailed information about the car's condition, increasing their confidence and trust in your listing.
                    </div>

                    <div className="heading" onClick={() => toggleAccordion(25)}>
                      Transparency & Honesty:
                    </div>
                    <div className={`contents ${activeIndex === 25 ? 'active' : ''}`}>
                      By conducting a professional inspection, you demonstrate transparency in the sale, which can reduce the chances of disputes and improve buyer satisfaction.
                    </div>

                    <div className="heading" onClick={() => toggleAccordion(26)}>
                      Increased Marketability:
                    </div>
                    <div className={`contents ${activeIndex === 26 ? 'active' : ''}`}>
                      Cars with a verified inspection report are more attractive to buyers, as they can be assured of the vehicle's quality and condition before making a purchase decision.
                    </div>
                    <div className="heading" onClick={() => toggleAccordion(27)}>
                      Highlight Key Selling Points:
                    </div>
                    <div className={`contents ${activeIndex === 27 ? 'active' : ''}`}>
                      Our inspection process not only identifies issues but also highlights the strengths of your vehicle, allowing you to emphasize these points in your listing.
                    </div>
                    <div className="heading" onClick={() => toggleAccordion(28)}>
                      Reduces Time to Sell:
                    </div>
                    <div className={`contents ${activeIndex === 28 ? 'active' : ''}`}>
                      An inspected car is likely to sell faster since buyers have all the necessary information upfront, reducing the need for prolonged negotiations or additional checks.
                    </div>
                    <div className="heading" onClick={() => toggleAccordion(29)}>
                      Professional Evaluation:
                    </div>
                    <div className={`contents ${activeIndex === 29 ? 'active' : ''}`}>
                      Our team of experts conducts the inspection using industry-standard practices, ensuring an objective and comprehensive evaluation of your vehicle.
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5 desktop-view ">
            <div className="card">
              <div className="applyForLoanDiv">
                <div>
                  <div className="content">
                    <h2>Check your eligibility & apply online</h2>

                    <p className="s-para m-0">
                      {" "}
                      Get instant approval. Become a Car owner today!
                    </p>
                  </div>
                  <a target="_blank" href="https://web.whatsapp.com/" className="button">
                    <div className="">Apply For a Loan</div>
                  </a>
                </div>
                <figure className="m-0">
                  <img src={applyForLoan} alt="" className="W100" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;