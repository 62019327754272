import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import testimonial1 from '../images/testimonial/customer1.png';
import quoteICon from '../images/testimonial/quoteICon.png';
import { FreeMode, Pagination } from 'swiper/modules';

function Testimonial() {
    const swiperRef = useRef(null);

    const testimonials = [
        {
            name: "Mr. Akshay Pingale from Mumbai",
            review: "I had a fantastic experience with Used Car Wale! The car valuation service was prompt and accurate, and I found the perfect used car through their extensive listings. Highly recommended!"
        },
        {
            name: "Ms. Priya Sharma. from Delhi",
            review: "Used Car Wale made buying a used car so easy and hassle-free. The door-to-door car valuation was a game-changer. I could trust their pricing and found a great deal on a car. Thank you!"
        },
        {
            name: "Venkateshwar K. from Bangalore",
            review: "I was skeptical about buying a used car, but Used Car Wale exceeded my expectations. The listings were detailed, and the customer service was exceptional. I love my new (used) car!"
        },
        {
            name: "Mrs. Anitya Gopala . from Chennai",
            review: "The convenience of having my car valued at my doorstep was incredible. Used Car Wale provided an honest valuation, and I quickly found a buyer through their platform. Highly satisfied!"
        },
        {
            name: "Mr. P. Tarachand from Hyderabad",
            review: "Used Car Wale offers a seamless experience for both buyers and sellers. The website is user-friendly, and the customer support team is very responsive. I got a great deal on my used car!"
        },
        {
            name: "Mrs. Sneha Jakula. from Pune",
            review: "From the comprehensive listings to the reliable valuation service, Used Car Wale has it all. I found a car that fits my budget and needs perfectly. Thanks for making it so easy!"
        },
        {
            name: "Mr. Bijoy from Kolkata",
            review: "I sold my car through Used Car Wale and couldn't be happier with the process. The door-to-door valuation service saved me a lot of time, and I got a fair price for my car. Great service!"
        },
        {
            name: "Mr. Dhiru Patel from Ahmedabad",
            review: "Buying a used car has never been easier. Used Car Wale's platform is intuitive, and the customer support is top-notch. I highly recommend them to anyone looking for a used car in India."
        },
        {
            name: "Mr. Ram Shekhawat from Jaipur",
            review: "Used Car Wale is the best platform for buying and selling used cars. The door-to-door valuation is a unique and valuable service. I got a fantastic deal on my car. Thank you!"
        },
        {
            name: "Mr. Rajat Shukla from Lucknow",
            review: "I was impressed with the professionalism and efficiency of Used Car Wale. The valuation was accurate, and the listings were extensive. I found exactly what I was looking for!"
        }
    ];

    return (
        <section className="testimoial-sect">
            <div className="container">
                <div className="row">
                    <div className="testimonial-slider">
                        <div className="head position-relative">
                            <h2 className="xlSize fw6 text-white fw6 section_title">Testimonial</h2>
                            <div className="navigationButton">
                                <div
                                    className="swiper-button-prev"
                                    onClick={() => swiperRef.current.swiper.slidePrev()}>
                                </div>
                                <div
                                    className="swiper-button-next"
                                    onClick={() => swiperRef.current.swiper.slideNext()}>
                                </div>
                            </div>
                        </div>
                        <Swiper
                            ref={swiperRef}
                            spaceBetween={10}
                            slidesPerView={3.3}
                            freeMode={true}
                            watchSlidesProgress={true}
                            breakpoints={{
                                320: { slidesPerView: 1, spaceBetween: 20 },
                                768: { slidesPerView: 2, spaceBetween: 40 },
                                1024: { slidesPerView: 3, spaceBetween: 50 },
                                1200: { slidesPerView: 3.3, spaceBetween: 50 },
                            }}
                            modules={[FreeMode, Pagination]}
                            className="TestMySwiper">
                            {testimonials.map((testimonial, index) => (
                                <SwiperSlide key={index}>
                                    <div className="item">
                                        <div className="reviews">
                                            <img src={quoteICon} alt="" className="iconImg" />
                                            <p className="clientReviews">{testimonial.review}</p>
                                            <div className="customerProfile">
                                                <img src={testimonial1} alt="" className="customerImg" />
                                                <h4 className="clientName d-inline">{testimonial.name}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;
