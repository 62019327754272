import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "../style/PopularBrands.css";

function PopularBrands() {
  const [brands, setBrands] = useState([]);
  const swiperRef = useRef(null); // Ref to access Swiper instance

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/brand/brand-list`
        );
        setBrands(response.data.allBrands);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="popular-brands">
      <div className="container">
        <div className="row">
          <div className="head position-relative">
            <h2 className="xlSize fw6">Check out Popular Brands</h2>
            <div className="navigationButton  ">

              <div
                className="swiper-button-prev"
                onClick={() => swiperRef.current.swiper.slidePrev()} // Slide previous
              >

              </div>
              <div
                className="swiper-button-next"
                onClick={() => swiperRef.current.swiper.slideNext()} // Slide next
              >

              </div>
            </div>
          </div>
          <Swiper
            ref={swiperRef} // Set the swiperRef to Swiper
            spaceBetween={10}
            slidesPerView={5}
            freeMode={true}
            watchSlidesProgress={true}
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }} // Attach custom buttons for navigation
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1000: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
              1200: {
                slidesPerView: 7,
                spaceBetween: 50,
              },
            }}
            className="mySwiper"
          >
            {brands.map((brand, index) => (
              <SwiperSlide key={index}>
                <div className="item">
                  <img
                    src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${brand.brand_img}`}
                    alt={brand.brand_name}
                    className="W100"
                  />
                  <h5 className="brandName">{brand.brand_name}</h5>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default PopularBrands;
