import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../style/ExploreCarDetails.css";
// import RecentSearch from "../components/RecentSearch";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SpecificationAccordion from "../components/SpecificationAccordian";
import FaqAccordion from "../components/FaqAccordion.jsx";
import applyForLoan from "../images/applyForLoan.png";
import startIcon from "../images/startIcon.png";
import carImg from "../images/cars/details1.png";
import icon1 from "../images/vector/icon1.png";
import icon2 from "../images/vector/icon2.png";
import icon3 from "../images/vector/icon3.png";
import icon4 from "../images/vector/icon4.png";

import MakeOffer from "../components/modals/MakeOffer";
import LoginModal from "../components/modals/LoginModal";
import ContactModal from "../components/modals/ContactModal";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

function CarDetails() {
  const [showContactModal, setShowContactModal] = useState(false);
  const [contactInfo, setContactInfo] = useState({});

  const [showMakeOffer, setShowOfferModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [car, setCar] = useState({});
    const [Recentcars, setRecentCars] = useState([]);
  let { carId } = useParams();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const formatPrice = (price) => {
    if (price === undefined || price === null) {
      return "Price not available"; // Return a default message
    }

    if (price >= 100000) {
      return `Rs. ${(price / 100000).toFixed(2)} Lakh`;
    }

    return `Rs. ${price.toLocaleString()}`;
  };

  const updateLoginState = (state) => {
    setIsLoggedIn(state);
  };

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  // Function to convert HTML to plain text
  const stripHtml = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/car/fetch-Car/${carId}`
        );
        const data = await response.json();
        setCar(data.car);
      } catch (error) {
        console.error("Error fetching cars:", error);
      }
    };
    const fetchrecentData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/car/car-list`
        );
        const data = await response.json();
        setRecentCars(data.car);
      } catch (error) {
        console.error("Error fetching cars:", error);
      }
    };
    fetchrecentData();
    fetchData();
  }, [carId]); // added carId to the dependency array

  return (
    <div>
      <section className="ActivePageHeader">
        <div className="container">
          <div className="row">
            <div className="d-flex align-items-center">
              <a href={"/"} className="active">
                Home
              </a>
              <span>
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </span>
              <a href={"/explore-car"} className="active">
                {" "}
                Used Cars
              </a>
              <span>
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </span>
              <span>
                {car.brand ? car.brand.brand_name : ""}{" "}
                {car.model ? car.model.model_name : ""}{" "}
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="singleCarMain">
        <div className="container">
          <div className="row ">
            <div className="col-md-7 leftSide carAllDetails">
              <div className="card border-none bg-none">
                <Swiper
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  spaceBetween={10}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  {car.front_view && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.front_view}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.front_left && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.front_left}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.front_right && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.front_right}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.left_view && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.left_view}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.right_view && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.right_view}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.rear_view && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.rear_view}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.rear_left && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.rear_left}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.rear_right && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.rear_right}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.odometer && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.odometer}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.engine && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.engine}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.chessis && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.chessis}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.interior && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.interior}`}
                      />
                    </SwiperSlide>
                  )}
                </Swiper>
                <Swiper
                  onSwiper={setThumbsSwiper}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper"
                >
                  {car.front_view && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.front_view}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.front_left && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.front_left}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.front_right && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.front_right}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.left_view && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.left_view}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.right_view && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.right_view}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.rear_view && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.rear_view}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.rear_left && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.rear_left}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.rear_right && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.rear_right}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.odometer && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.odometer}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.engine && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.engine}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.chessis && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.chessis}`}
                      />
                    </SwiperSlide>
                  )}
                  {car.interior && (
                    <SwiperSlide>
                      <img
                        src={`https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.interior}`}
                      />
                    </SwiperSlide>
                  )}
                </Swiper>
              </div>
            </div>

            <div className="col-md-5 rightSide carhighlightsDetails">
              <div className="card bg-none border-none">

                {car && (
                  <h5 className="card-title">
                    {car.brand ? car.brand.brand_name : ""}{" "}
                    {car.model ? car.model.model_name : ""}{" "}
                    {car.varient ? car.varient.varient_name : ""}
                  </h5>
                )}
                <div className="cardDetailsUpper">
                  <div className="carCondition d-flex gap-2 align-items-center  ">

                    <figure className="m-0">
                      <img src={startIcon} alt="" className="W100 icons" />
                    </figure>              <span>Condition</span>
                    <span className="ConditionStatus">Excellent</span>
                  </div>
                  <ul className="p-0 grid3">
                    <li>
                      Year
                      <h6 className="year">   {car.manufacturing_year}</h6>
                    </li>
                    <li>
                      Location
                      <h6 className="Location">
                        {" "}
                        {car.current_location}
                      </h6>
                    </li>
                    <li>
                      KM Driven
                      <h6 className="KMDriven">   {car.kms_driven}km</h6>
                    </li>
                  </ul>
                  <div id="desc">
                    <div className="article">
                      {/* Render the HTML description */}
                      <p
                        className="s-para"
                        dangerouslySetInnerHTML={{
                          __html: car.car_description,
                        }}
                      />
                      {/* Display the plain text description */}
                      {/* <p className="plain-text s-para">
                        {stripHtml(car.car_description)}
                      </p> */}
                      {/* <p
                        className="moretext s-para"
                        style={{ display: isExpanded ? "block" : "none" }}
                      >
                       <p className="plain-text s-para">
                        {stripHtml(car.car_description)}
                      </p>
                      </p> */}
                    </div>
                    {/* <div>
                      <span className="moreless-button" onClick={toggleText}>
                        {isExpanded ? "Read less" : "Read more"}
                      </span>
                    </div> */}
                  </div>

                </div>
                <div className="cardDetailsMiddle">
                  <p className="priceFrom mb-0">
                    <span> {formatPrice(car.price)}</span>
                  </p>
                  <div className=" ">
                    {localStorage.getItem("authUser") ? (
                      <button
                        onClick={() => setShowOfferModal(true)}
                        className="themeBtn"
                      >
                        Make Offer
                      </button>
                    ) : (
                      <button
                        onClick={() => setShowUserModal(true)}
                        className="themeBtn  e"
                      >
                        Make Offer
                      </button>
                    )}
                  </div>
                  <div className="column2">
                    <button
                      onClick={() => {
                        setShowContactModal(true);
                        setContactInfo(
                          car.dealer
                            ? {
                              title: "Dealer Contact Details",
                              name: car.dealer?.name,
                              phone: car.dealer?.phone,
                            }
                            : {
                              title: "UsedCarWale Contact Details",
                              name: "UsedCarWale Support",
                              phone: "123-456-7890",
                            }
                        );
                      }}
                      className="ViewContactModal  blueBorderBtn"
                    >
                      {car.dealer
                        ? "View Dealer Contact"
                        : "View UsedCarWale Contact Details"}
                    </button>
                  </div>
                </div>

                <div>
                  {/* <div className="ribbon-2">
                    Wohoo!! Congratulations <b>30%</b> OFF
                  </div> */}



                  <div>
                    <div className="applyForLoanDiv">
                      <div>
                        <div className="content">
                          <h2>Check your eligibility & apply online</h2>

                          <p className="s-para m-0">
                            {" "}
                            Get instant approval. Become a Car owner today!
                          </p>
                        </div>
                        <a target="_blank" href="https://web.whatsapp.com/" className="button">
                          <div className="">Apply For a Loan</div>
                        </a>
                      </div>
                      <figure className="m-0">
                        <img src={applyForLoan} alt="" className="W100" />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showMakeOffer && (
          <MakeOffer
            onClose={() => setShowOfferModal(false)}
            price={car.price}
            car_id={car.id}
          />
        )}
        <div>
        {showUserModal && (
            <LoginModal
              pathRoute={`/car-details/${car.brand.brand_name.replace(/\s+/g, '-').toLowerCase()}/${car.id}`}
              onClose={() => setShowUserModal(false)}
              onLogin={() => updateLoginState(true)}
            />
          )}
        </div>
        <div>
          {showContactModal && (
            <ContactModal
              onClose={() => setShowContactModal(false)}
              title={contactInfo.title}
              name={contactInfo.name}
              phone={contactInfo.phone}
            />
          )}
        </div>
      </section>
      <section className="carFullDesc">
        <div className="container">
          <div className="row">
            <div className="col-md-7 leftSide">
              <div className="box">
                <h5>Details :</h5>
                <div className="innerBox">
                  <h6>Description</h6>
                  <p className="grey sSize">  <p
                        className="s-para"
                        dangerouslySetInnerHTML={{
                          __html: car.car_description||'The Bugatti Veyron 16.4 Grand Sport is an extraordinary masterpiece that blends jaw-dropping performance with unparalleled luxury and elegance. Designed as the convertible version of the legendary Veyron, the Grand Sport redefines open-top driving, offering a unique combination of speed, exclusivity, and craftsmanship.',
                        }}
                      /></p>
                </div>
                <div className="cardOverview grid3">
                  <div className="items">
                    <figure>
                      <img src={icon1} alt="" className="  icons" />
                    </figure>
                    <h6 className="grey sSize">Year</h6>
                    <h6 className="title">
                    {car.manufacturing_year}
                    </h6>
                  </div>
                  <div className="items">
                    <figure>
                      <img src={icon2} alt="" className="  icons" />
                    </figure>
                    <h6 className="grey sSize">Kms Driven</h6>
                    <h6 className="title">
                    {car.kms_driven} km
                    </h6>
                  </div>
                  <div className="items">
                    <figure>
                      <img src={icon2} alt="" className="  icons" />
                    </figure>
                    <h6 className="grey sSize">Registration No.</h6>
                    <h6 className="title">
                    {car.registration_number}
                    </h6>
                  </div>
                  <div className="items">
                    <figure>
                      <img src={icon3} alt="" className="  icons" />
                    </figure>
                    <h6 className="grey sSize">Ownership</h6>
                    <h6 className="title">
                    {car.ownership}
                    </h6>
                  </div>
                  <div className="items">
                    <figure>
                      <img src={icon4} alt="" className="  icons" />
                    </figure>
                    <h6 className="grey sSize">Location </h6>
                    <h6 className="title">
                    {car.current_location}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 rightSide">
              <div className="innerBox">
                <h6 className="sticky">Recent Searches :</h6>
                {Recentcars.slice(0, 3).map((rcar) => (
                <div class=" singleCarItems" key={rcar.id}>
                    
                      <div class="card bg-none"><div class="card-body p-0">
                      <a class="link-color" href={`/car-details/${rcar.brand.brand_name.replace(/\s+/g, '-').toLowerCase()}/${rcar.id}`}>
                    <figure className="m-0">
                      <img  src={rcar.front_view ? `https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${rcar.front_view}`: carImg} alt="" className="W100" />
                    </figure>
                    <div class="card-content"><div class="carOverview">
                      <div class="dGridMobile d-flex justify-content-between">
                        <h5 class="card-title mSize m-0">  {rcar.brand ? rcar.brand.brand_name : ""}
                        {rcar.model ? rcar.model.model_name : ""}</h5>

                      </div>
                      <div class="carHighlights d-flex gap-2">
                        <p class="xsSize fw6 grey my-auto">{rcar.kms_driven} kms</p>
                        <span class="separator">|</span>
                        <p class="xsSize fw6 grey my-auto">{rcar.fuel_type}</p>
                        <span class="separator">|</span>
                        <p class="xsSize fw6 grey my-auto">{rcar.current_location}</p>
                      </div>
                    </div>
                      <div class="carPriceSec d-flex justify-content-between">
                        <h6 class="carName theme mSizefw5 my-auto">{formatPrice(rcar.price)}</h6>
                       <Link to={`/car-details/${rcar.brand.brand_name.replace(/\s+/g, '-').toLowerCase()}/${rcar.id}`}class="borderBtn border-none">View Details</Link>
                      </div>
                    </div>
                    </a>
                </div>
                </div>

                </div>

                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faqAccordion">
        <div className="container">
          <FaqAccordion />
        </div>
      </section>
      {/* 
      <section className="specificationAccordion">
        <div className="container">
          <SpecificationAccordion />
        </div>
      </section> */}

    </div>
  );
}

export default CarDetails;
