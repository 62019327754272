import { Swiper, SwiperSlide } from "swiper/react";
import React, { useRef, useState } from "react";
// Import images directly
import partner1 from '../images/main/partner1.jpg';
import partner2 from '../images/main/partner1.jpg';
import partner3 from '../images/main/partner1.jpg';
import partner4 from '../images/main/partner1.jpg';
import partner5 from '../images/main/partner1.jpg';
import partner6 from '../images/main/partner1.jpg';
import partner7 from '../images/main/partner1.jpg';

// Static data for partners with imported image paths
const staticPartners = [
    { partner_img: partner1 },
    { partner_img: partner2 },
    { partner_img: partner3 },
    { partner_img: partner4 },
    { partner_img: partner5 },
    { partner_img: partner6 },
    { partner_img: partner7 },
];

function PartnerSlider() {
    const [partners] = useState(staticPartners); // Use static partner data
    const swiperRef = useRef(null); // Ref to access Swiper instance

    const handlePrevClick = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const handleNextClick = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    return (
        <section className="partner-slider">
            <div className="container">
                <div className="row">
                    <div className="head position-relative">
                        <h2 className="xlSize fw6">Our Trusted Partners</h2>

                        <div className="navigationButton  ">

                            <div
                                className="swiper-button-prev"
                                onClick={() => swiperRef.current.swiper.slidePrev()} // Slide previous
                            >

                            </div>
                            <div
                                className="swiper-button-next"
                                onClick={() => swiperRef.current.swiper.slideNext()} // Slide next
                            >

                            </div>
                        </div>
                    </div>

                    <Swiper
                        ref={swiperRef} // Set the swiperRef to Swiper
                        spaceBetween={10}
                        slidesPerView={5}
                        freeMode={true}
                        watchSlidesProgress={true}
                        breakpoints={{
                            320: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 4,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 6,
                                spaceBetween: 50,
                            },
                        }}
                        className="mySwiper"
                        onSwiper={(swiperInstance) => {
                            swiperRef.current.swiper = swiperInstance; // Store the Swiper instance
                        }}
                    >
                        {partners.map((partner, index) => (
                            <SwiperSlide key={index}>
                                <div className="item">
                                    <img src={partner.partner_img} alt="" className="customerImg" />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    );
}

export default PartnerSlider;
