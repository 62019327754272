import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import defaultCarImage from "../images/cars/carValuation.png";

function TrendingSearches() {
  const [cars, setCars] = useState([]);
  const [wishlist, setWishlist] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/car/car-list`
        );
        const data = await response.json();
        setCars(data.car);
      } catch (error) {
        console.error("Error fetching cars:", error);
      }
    };
    fetchData();
  }, []);



  const formatPrice = (price) => {
    if (price >= 10000000) {
      return `Rs. ${(price / 10000000).toFixed(2)} Crore`;
    } else if (price >= 100000) {
      return `Rs. ${(price / 100000).toFixed(2)} Lakh`;
    }
    return `Rs. ${price.toLocaleString()}`;
  };
  const toggleWishlist = (carId) => {
    setWishlist((prevState) => ({
      ...prevState,
      [carId]: !prevState[carId], // Toggle the state for the clicked car
    }));
  };
  return (
    <section className="trendingSearches default-cars-advertise">
      <div className="container">
        <div className="row">
          <div className="head">
            <h2 className="xlSize fw6">Trending Searches Near You</h2>
            <a href={"/explore-car"} className="btn p-0 ">
              VIEW ALL  <ion-icon name="chevron-forward-outline"  ></ion-icon>
            </a>
          </div>
          <div className="sortingAccordingType sortingTabs">
            <div className="typeTabs tabsFields">
              <Link to={"/explore-car"} className="tabsLink ">All</Link>
              <Link to={"/explore-car"} className="tabsLink active ">Petrol</Link>
              <Link to={"/explore-car"} className="tabsLink ">Diesel</Link>
              <Link to={"/explore-car"} className="tabsLink  ">Electric</Link>
              <Link to={"/explore-car"} className="tabsLink">Cng</Link>
            </div>
          </div>
          {cars.slice(1, 4).map((car) => (
            <div className="col-md-4" key={car.id}>
              <div className="card">
                <div className="card-body p-0">
                  <figure className="m-0">
                    <img
                      src={
                        car.front_view
                          ? `https://usedcarautoscan.s3.ap-south-1.amazonaws.com/${car.front_view}`
                          : defaultCarImage
                      }
                      alt=""
                      className="arrow-img W100"
                    />
                    <button
                      className={`wishlistBtn ${wishlist[car.id] ? 'active' : ''}`}
                      onClick={() => toggleWishlist(car.id)}
                    >
                      <ion-icon name="bookmark-outline"></ion-icon>
                    </button>
                  </figure>
                  <Link to={`/car-details/${car.brand.brand_name.replace(/\s+/g, '-').toLowerCase()}/${car.id}`} key={car.id} className="link-color">

                    <div className="card-content">
                      <div className="carOverview">
                        <div className=" dGridMobile d-flex justify-content-between">
                          <h5 className="card-title mSize my-0">
                            {car.brand ? car.brand.brand_name : ""}{" "}
                            {car.model ? car.model.model_name : ""}
                          </h5>
                          <h6 className="carType">Petrol</h6>
                        </div>
                        <div className="carHighlights d-flex gap-2">
                        <p className="sSize fw6">{car.kms_driven} kms</p> <span className="separator">|</span>
                          <p className="sSize fw6">{car.fuel_type}</p> <span className="separator">|</span>
                          <p className="sSize fw6">{car.current_location}</p>
                        </div>
                      </div>
                      <div className="carPriceSec d-flex justify-content-between">

                        <h6 className="carName theme lSize fw5">
                          {formatPrice(car.price)}
                        </h6>
                        <a href="sellCarDetails" className="borderBtn ">
                          View Details
                        </a>
                      </div>


                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default TrendingSearches;
