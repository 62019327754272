import React, { useState, useEffect } from 'react';
import '../style/EmiCalculator.css';

function EmiCalculator() {
  const [loanAmount, setLoanAmount] = useState(2000000);
  const [interestRate, setInterestRate] = useState(10);
  const [tenure, setTenure] = useState(5);
  const [emi, setEmi] = useState(0);
  const [schedule, setSchedule] = useState([]);

  const calculateEmi = () => {
    const principal = loanAmount;
    const rate = interestRate / 100 / 12;
    const time = tenure * 12;
    const emiValue = (principal * rate * (1 + rate) ** time) / ((1 + rate) ** time - 1);
    setEmi(emiValue.toFixed(2));

    const scheduleData = [];
    let balance = principal;
    for (let month = 1; month <= time; month++) {
      const interest = balance * rate;
      const principalPaid = emiValue - interest;
      balance -= principalPaid;

      // Push data only for every 12th month
      if (month % 12 === 0 || month === time) {
        scheduleData.push({
          month,
          principalPaid: principalPaid.toFixed(2),
          interestPaid: interest.toFixed(2),
          balance: balance.toFixed(2),
        });
      }
    }
    setSchedule(scheduleData);
  };

  useEffect(() => {
    calculateEmi();
  }, [loanAmount, interestRate, tenure]);

  return (
    <div className="emi-calculator container   border rounded shadow-sm bg-light">
      <div className="row">
        <div className="col-md-6 mb-4">
          <h2 className="text-primary">Customize Car Loan EMI</h2>
          <p className="text-muted">Get attractive rates on car loans with instant approval.</p>
          <div className="loan-details">
            <p className="h5 mb-4">₹{emi} EMI For {tenure} years</p>
            <div className="slider-group mb-3">
              <label className="form-label">Loan amount: ₹{loanAmount.toLocaleString()}</label>
              <input
                type="range"
                className="form-range"
                min="100000"
                max="5000000"
                step="100000"
                value={loanAmount}
                onChange={(e) => setLoanAmount(parseInt(e.target.value))}
              />
              <input
                type="number"
                className="form-control mt-2"
                value={loanAmount}
                onChange={(e) => setLoanAmount(parseInt(e.target.value))}
              />
            </div>
            <div className="slider-group mb-3">
              <label className="form-label">Tenure: {tenure} Years</label>
              <input
                type="range"
                className="form-range"
                min="1"
                max="8"
                value={tenure}
                onChange={(e) => setTenure(parseInt(e.target.value))}
              />
              <input
                type="number"
                className="form-control mt-2"
                value={tenure}
                onChange={(e) => setTenure(parseInt(e.target.value))}
              />
            </div>
            <div className="slider-group mb-3">
              <label className="form-label">Interest Rate: {interestRate}%</label>
              <input
                type="range"
                className="form-range"
                min="8"
                max="20"
                step="0.5"
                value={interestRate}
                onChange={(e) => setInterestRate(parseFloat(e.target.value))}
              />
              <input
                type="number"
                className="form-control mt-2"
                value={interestRate}
                onChange={(e) => setInterestRate(parseFloat(e.target.value))}
              />
            </div>
          </div>
          <button className="btn btn-primary mt-3" onClick={calculateEmi}>
            Calculate EMI
          </button>
        </div>
        <div className="col-md-6">
          <div className="emi-result p-3 bg-white rounded shadow-sm">
            <h3 className="text-success mb-3">EMI: ₹{emi}</h3>
            <div className="emi-schedule table-responsive">
              <table className="table table-striped">
                <thead className="table-dark">
                  <tr>
                    <th>Months</th>
                    <th>Principal</th>
                    <th>Interest</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {schedule.map((item, index) => (
                    <tr key={index}>
                      <td>{item.month}</td>
                      <td>₹{parseFloat(item.principalPaid).toLocaleString()}</td>
                      <td>₹{parseFloat(item.interestPaid).toLocaleString()}</td>
                      <td>₹{parseFloat(item.balance).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmiCalculator;
